import styles from './Button.module.css'
import dice from '../../img/icon-dice.svg'
import { useContext } from 'react';
import { btnContext } from '../../Context';

interface IStyle {
  style?: number,
  text?: string,
  isClicking: number,
  handleClick: (value: number) => void;
}

export const Button: React.FC<IStyle> = ({ style, text, isClicking, handleClick }) => {

  // Check Light Theme Current State
  const {light, setLight} = useContext(btnContext);

  // Var for theme - ? is if, : is else
  // Define style Class Based on Light State
  let currentTheme = light ? 'light' : 'dark';
  
  // Switch Current Theme
  const switchTheme = () => {
    setLight(light ? false : true)
    // console.log(light);
  };

  // Count User Clicks
  const clickEvent = () => {
    handleClick(++isClicking);
    // console.log("Clicks", isClicking);
  };

  switch (style) {

    // Switch Layout Button
    case 1: {
      return (
        <button onClick={switchTheme} className={`${styles.switchButton} ${styles[currentTheme]}`}>
          {text}
        </button>
      )
    }

    // Randomize Button
    default: {
      return (
        <button onClick={clickEvent} className={`${styles.button} ${styles[currentTheme]}`}>
          <img src={dice} />
        </button>
      )
    }


  }
}
