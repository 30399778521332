import { useContext } from 'react';
import styles from './Advice.module.css'
import { btnContext } from '../../Context';

export interface IAdvice {
  id?: number | null;
  advice?: string | null;
}

export const Advice: React.FC<IAdvice> = ({id, advice}) => {

  const {light} = useContext(btnContext);
  let currentTheme = light ? 'light' : 'dark';

  return (
    <div className={`${styles['advice-text']} ${styles[currentTheme]}`}>
      <h2>
        Advice #{id}
      </h2>
      <q>
        {advice}
      </q>
    </div>
  )
}
