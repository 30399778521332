import { useEffect, useState } from 'react';
import styles from './App.module.css';
import { Advice, IAdvice } from './components/Advice';
import { Button } from './components/Button';
import divider from './img/pattern-divider-desktop.svg';
import {btnContext} from './Context'

function App() {

  // Theme State
  const [light, setLight] = useState(false);

  // Change Btn Name Based On Current Theme
  let changeTheme = light ? 'dark' : 'light';
  
  // Define style Class Based on Light State
  let currentTheme = light ? 'light' : 'dark';

  // Button Click State
  const [click, setClick] = useState(0);

  // Advice State
  const [getAdvice, setAdvice] = useState<IAdvice>({
    id: null,
    advice: null
  });

  // Trigger Advice Change each time the Button Click State is incremented
  useEffect(() => {
    fetch('https://api.adviceslip.com/advice', { cache: "no-store" })
      .then(res => res.json())
      .then(res => {
        setAdvice({ id: res.slip.id, advice: res.slip.advice })
      })
    // console.log(setAdvice);
  }, [click]);

  // Change body bg color when theme is updated in the context
  useEffect(() => {
    switch (light) {
      case true:
        document.body.classList.add('light');
        break;
      case false:
        default:
        document.body.classList.remove('light');
        break;
    }
  }, [light])


  // HTML Return
  return (
    
       <btnContext.Provider value={{ light, setLight }}>

      <div className={styles.buttonWrap}>
        <Button handleClick={setClick} isClicking={click} style={1} text={changeTheme} />
      </div>

      <div className={`${styles.container} ${styles[currentTheme]}`}>
        <Advice id={getAdvice.id} advice={getAdvice.advice} />
        <img src={divider} />
        <div className={styles['random-button']}>
          <Button handleClick={setClick} isClicking={click} />
        </div>
      </div>
      </btnContext.Provider>
      
  );
}

export default App;